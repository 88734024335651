<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div
    v-else-if="campaign === null"
    class="text-center"
  >
    <h2>Campaign {{ $route.params.campaignId }} not found</h2>
    <p>
      <router-link :to="{name: 'campaigns', query}">
        Go Back
      </router-link>
    </p>
  </div>
  <div
    v-else-if="adGroup === null"
    class="text-center"
  >
    <h2>Ad Group {{ $route.params.adGroupId }} not found</h2>
    <p>
      <router-link :to="{name: 'campaigns', query}">
        Go Back
      </router-link>
    </p>
  </div>
  <div v-else>
    <div class="pb-3">
      <CampaignBreadcrumb
        :campaign="campaign"
        :adgroup="adGroup"
      />
    </div>
    <TabMenu
      :model="items"
      :active-index="1"
    />
    <router-view
      :selected-profile="selectedProfile"
      :campaign="campaign"
      :ad-group="adGroup"
    />
  </div>
</template>

<script>
import CampaignBreadcrumb from '../../../CampaignBreadcrumb.vue';
import A2CAClient from '../../../../api/a2caAuthorizedClient';

export default {
  components: { CampaignBreadcrumb },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      campaign: null,
      adGroup: null,
      items: [
        {
          label: 'Targeting',
          icon: 'pi pi-fw pi-home',
          to: { name: 'adGroupTargeting', params: { campaignId: this.$route.params.campaignId, adGroupId: this.$route.params.adGroupId }, query: this.query },

        },
        {
          label: 'Products',
          icon: 'pi pi-fw pi-calendar',
          to: { name: 'adGroupProducts', params: { campaignId: this.$route.params.campaignId, adGroupId: this.$route.params.adGroupId }, query: this.query },
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    const client = new A2CAClient(this.query);
    const campaignRequest = client.getCampaign(
      this.selectedProfile.profileId,
      this.selectedProfile.region,
      this.$route.params.campaignId,
    );
    const adGroupRequest = client.getAdGroup(this.selectedProfile.profileId,
      this.selectedProfile.region,
      this.$route.params.adGroupId);

    const [campaign, adGroup] = await Promise.all([campaignRequest, adGroupRequest]);

    // Campaign exists
    if (campaign.campaignId !== null) {
      this.campaign = campaign;
    }
    // Ad group exists
    if (adGroup.adGroupId !== null) {
      this.adGroup = adGroup;
    }
    this.loading = false;
  },
};
</script>
