<template>
  <Breadcrumb
    :home="home"
    :model="items"
  />
</template>
<script>
export default {
  inject: ['query'],
  props: {
    campaign: {
      type: Object,
      required: false,
      default: null,
    },
    adgroup: {
      type: Object,
      required: false,
      default: null,
    },

  },
  data() {
    return {

    };
  },
  computed: {
    home() {
      return { label: 'All Campaigns', to: { name: 'home', query: this.query } };
    },
    items() {
      const items = [];
      if (this.campaign !== null) {
        items.push({
          label: `Campaign ${this.campaign.name}`, to: { name: 'adGroups', params: { id: this.campaign.campaignId }, query: this.query },
        });
      }
      if (this.adgroup !== null) {
        items.push({
          label: `Ad Group ${this.adgroup.name}`, to: { name: 'adGroupTargeting', params: { campaignId: this.campaign.campaignId, adGroupId: this.adgroup.adGroupId }, query: this.query },
        });
      }
      return items;
    },
  },
};
</script>
